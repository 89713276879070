import axios from "axios";
import store from "@/store";
// import _ from "lodash";
import router from "../router";

//
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
//

// // const customPlugin = new CustomPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_APPINSIGHTS_KEY,
    // extensions: [customPlugin],
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "access_token"
)}`;

axios.interceptors.request.use((config) => {
  // Adding Request Headers
  config.headers["Request-Origin"] = "portal";

  if (config?.params) {
    config.params.currency = localStorage.getItem("selectedCurrency");
  }

  return config;
});

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    // Handle the response here
    // appInsights.TrackDependency("Vue js ", response);
    return response;
  },
  (error) => {
    // Handle errors here
    appInsights.trackException(error?.response?.data?.message);

    return Promise.reject(error);
  }
);

function get(url, config) {
  // const user = store.getters.getUser;
  // let urlParams = getParams(url);
  // if (user.store_id) {
  //   if (_.isEmpty(urlParams)) {
  //     url = url + "?expected_store_id=" + user.store_id;
  //   } else {
  //     url = url + "&expected_store_id=" + user.store_id;
  //   }
  // }
  const response = axios.get(url, config);
  return response.catch(function (error) {
    if (error.response?.status == "409") {
      store.dispatch("alerts/info", "Different store, Reloading ...");
      setTimeout(() => router.go(0), 700);
    }
    if (error.response?.status == "401") {
      store.dispatch("logout");
    } else {
      store.dispatch(
        "alerts/error",
        error.response?.data?.message || error?.message
      );
    }

    return Promise.reject(error);
    // throw error;
  });

  // return response
}

function post(url, data, config) {
  data = formatData("post", data, config);
  return axios.post(url, data, config);
}

function put(url, data, config) {
  data = formatData("post", data, config);
  return axios.put(url, data, config);
}

function patch(url, data, config) {
  data = formatData("post", data, config);
  return axios.patch(url, data, config);
}

function delete_method(url, config) {
  return axios.delete(url, config);
}

function formatData(action, data, config) {
  if (action == "post") {
    const user = store.getters.getUser;
    if (user.store_id) {
      data.store_id = user.store_id;
    }
  }
  if (config?.headers["Content-Type"] == "multipart/form-data") {
    data = jsonToFormData(data);
  }
  return data;
}

function jsonToFormData(data) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

// function getParams(url) {
//   let uri = url.split("?");
//   if (uri.length == 2) {
//     let vars = uri[1].split("&");
//     let getVars = {};
//     let tmp = "";
//     vars.forEach(function (v) {
//       tmp = v.split("=");
//       if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
//     });
//     return getVars;
//   }
//   return [];
// }
export default { post, get, put, patch, delete_method };
