<template>
  <span>
    <Icons
      :name="getIcons"
      :class="status"
    />
  </span>
</template>
<script>
export default {
  components: {
    Icons: () => import("@/components/base/icons.vue"),
  },
  props: {
    name: {
      type: String,
      default() {
        return "two_wheeler";
      },
    },
    status: {
      type: String,
      default() {
        return "in_active";
      },
    },
  },
  computed: {
    getIcons() {
      if (this.name == "four_wheeler") return "marker-4w";
      else if (this.name == "three_wheeler") return "marker-3w";
      else return "marker-2w";
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .active svg g
  fill : #35af35 !important
  stroke: black !important
  stroke-width: 5rem !important

:deep .in_active, :deep .inactive
    svg g
        fill : #f0ae38 !important
        stroke: black !important
        stroke-width: 5rem !important

:deep .offline svg g
  fill : #df4f4f
  stroke: black !important
  stroke-width: 5rem !important
</style>
